/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib components
import { Container } from 'react-grid-system';

// Page wrappers
import Layout from '../shared/PageLayout';
import Seo from '../shared/Seo';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function NotFoundPage() {
  /* -------------------------------- CONST --------------------------------- */
  const IS_BROWSER = typeof window !== 'undefined' && window;

  /* ****************************** RENDERING ******************************* */
  return (
    IS_BROWSER && (
      <Layout>
        <Seo title="404: Not found" />
        <Container fluid>
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Container>
      </Layout>
    )
  );
}

export default NotFoundPage;
